"use client";

import clsx from "clsx";
import type { ChangeEventHandler } from "react";
import { CheckboxWithLabel } from "@/components/Checkbox/CheckboxWithLabel.component";
import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type NewsletterSignUpPermissionCheckboxProps = {
  readonly className?: string | null;
  readonly isRequired?: boolean;
  readonly isDisabled?: boolean;
  readonly name?: string;
  readonly onChange: ChangeEventHandler<HTMLInputElement>;
};
export function NewsletterSignUpPermissionCheckbox({
  className,
  isRequired,
  isDisabled,
  onChange
}: NewsletterSignUpPermissionCheckboxProps) {
  const {
    locale
  } = useSite();
  const brandUrl = {
    da: "https://watchmedier.dk/service/newsletterpartners",
    de: "https://watchmedier.dk/service/newsletterpartners_en",
    en: "https://watchmedier.dk/service/newsletterpartners_en",
    no: "https://watchmedier.dk/service/newsletterpartners_en",
    sv: "https://watchmedier.dk/service/newsletterpartners_en"
  }[locale];
  const brandName = {
    da: "Watch Medier A/S",
    de: "Watch Media GmbH",
    en: "Watch Medier A/S",
    no: "Watch Media Norway AS",
    sv: "Watch Media Sweden AB"
  }[locale];
  const andOtherBrandsName = {
    da: "vores brands og koncernforbundne selskaber",
    de: "unsere Marken und Konzerngesellschaften",
    en: "our brands and group companies",
    no: "våre merkevarer og samarbeidspartnere",
    sv: "våra varumärken och dotterbolag"
  }[locale];
  const brandLink = <Link className={clsx("text-primary hover:underline", className)} href={brandUrl} rel="noreferrer" openInNewTab>
      {brandName}
    </Link>;
  const otherBrandLink = <Link className={clsx("text-primary hover:underline", className)} href={brandUrl} rel="noreferrer" openInNewTab>
      {andOtherBrandsName}
    </Link>;
  const cookieAndPrivacyLink = <Link className="text-primary hover:underline" href="/service/privacypolicy" position="newsletter-sign-up-terms" rel="noreferrer" openInNewTab>
      <Translation da="privatlivspolitik og cookiepolitik" de="Datenschutzrichtlinie und Cookie-Richtlinie" en="privacy policy and cookie policy" no="personvernerklæring og cookiepolicy" sv="integritetspolicy och cookiepolicy" />
    </Link>;
  return <CheckboxWithLabel className="text-xs" isDisabled={isDisabled} isRequired={isRequired} name="newsletterAcceptTerms" onChange={onChange} data-sentry-element="CheckboxWithLabel" data-sentry-component="NewsletterSignUpPermissionCheckbox" data-sentry-source-file="NewsletterSignUpPermissionCheckbox.component.tsx">
      {{
      da: inlineJsx`Jeg ønsker at modtage tilpassede nyhedsbreve på e-mail fra ${brandLink} herunder information om ${otherBrandLink}, konferencer, kurser, netværk, konkurrencer og digitale tjenester.`,
      de: inlineJsx`Ich möchte personalisierte E-Mail-Newsletter von ${brandLink} einschließlich Informationen über ${otherBrandLink} erhalten, Konferenzen, Kurse, Networking, Wettbewerbe und digitale Dienste.`,
      en: inlineJsx`I want to receive customized email newsletters from ${brandLink}, including information about ${otherBrandLink}, conferences, courses, networking, competitions and digital services.`,
      no: inlineJsx`Jeg ønsker å motta persontilpassede nyhetsbrev på e-post fra ${brandLink}, inkludert informasjon om ${otherBrandLink}, konferanser, kurs, nettverksbygging, konkurranser og digitale tjenester.`,
      sv: inlineJsx`Jag vill få personligt anpassade nyhetsbrev via e-post från ${brandLink} inklusive information om ${otherBrandLink}, konferenser, kurser, nätverkande, tävlingar och digitala tjänster.`
    }[locale]}
      <br />
      <br />
      {{
      da: inlineJsx`Med mit samtykke accepterer jeg også behandling af mine persondata, og at der indsamles information om min interaktion med markedsføringsmeddelelser (såsom åbning og læsning) ved hjælp af tracking pixels og lignende teknologier. Læs mere i vores ${cookieAndPrivacyLink}. Samtykket kan altid tilbagekaldes via afmeldingslink i nyhedsbreve.`,
      de: inlineJsx`Mit meiner Zustimmung erkläre ich mich auch mit der Verarbeitung meiner personenbezogenen Daten einverstanden und damit, dass Informationen über meine Interaktion mit Marketingnachrichten (wie z. B. das Öffnen und Lesen) mit Hilfe von Tracking-Pixeln und ähnlichen Technologien erfasst werden. Weitere Informationen finden Sie in unserer ${cookieAndPrivacyLink}. Die Einwilligung kann jederzeit über den Abmeldelink in Newslettern widerrufen werden.`,
      en: inlineJsx`With my consent, I also agree to the processing of my personal data and that information about my interaction with marketing messages (such as opening and reading) is collected using tracking pixels and similar technologies. Read more in our ${cookieAndPrivacyLink}. Consent can always be revoked via the unsubscribe link in newsletters.`,
      no: inlineJsx`Mitt samtykke innebærer også at jeg godkjenner at behandling av mine personopplysninger og at informasjon om min interaksjon med markedsføringsmeldinger (som åpning og lesing) samles inn ved hjelp av sporingspiksler og lignende teknologier. Les mer i vår ${cookieAndPrivacyLink}. Samtykket kan alltid trekkes tilbake via avmeldingslenken i nyhetsbrev.`,
      sv: inlineJsx`Mitt samtycke innebär även att jag godkänner att mina personuppgifter behandlas och att information om min interaktion med marknadsföringsmeddelanden (såsom öppning och läsning) samlas in med hjälp av spårningspixlar och liknande teknik. Läs mer i vår ${cookieAndPrivacyLink}. Samtycket kan alltid återkallas via avregistreringslänken i nyhetsbreven.`
    }[locale]}
    </CheckboxWithLabel>;
}